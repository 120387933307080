#offcanvas-menu.off-canvas {
    background-color: rgba($color: $black, $alpha: .95);

    .close-button {
        color: $light-gray;
    }

    .vertical.menu {
        margin-top: 2.5rem;

        li {
            border-bottom: 1px solid $ink-gray;
            a {
                color: $coral;
            }
        }
    }
}