// animate setting
.animate__animated.animate__fadeInUpBig {
      --animate-duration: 1500ms;
      --animate-delay: 1000ms;
}

@keyframes reveal {
  from { opacity: 0; clip-path: inset(0% 0% 75% 0%); }
  to { opacity: 1; clip-path: inset(0% 0% 0% 0%); }
}

.revealing-image {
  animation: auto linear reveal both;
  animation-timeline: view();
  animation-range: entry 25% cover 50%;
}