// img
img.thumbnail {
    background-color: $white;
    margin: 0 1.5rem;
    @include breakpoint (small only){
        margin: 0;
    }
}
// media-object
.media-object {
    img {
        width: 100%;
    }
}
.media-object.stack-for-medium {
    @include breakpoint (medium down) {
        flex-direction: column !important;
    }
}

// image link hover action
.gray-screen {
    display: block;
    background-color: $gray;
    transition: transform 0.2s linear;
    transition-property: background-color;
    &:hover,
    &:focus {
        background-color: $white;
    }
    img {
        mix-blend-mode: multiply;
    }
}
// works hero slider
#works {
    .orbit .orbit-container .orbit-slide {
        .orbit-figure {
            img {
                width: 100%;
                object-fit:cover;
                object-position: top;
                aspect-ratio: 16 / 9;
                overflow-y: hidden;
            }
        }
    }
}