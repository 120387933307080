// heading
#top {
  margin-bottom: 10rem;

  h2,
  h3 {
    text-align: center;
    @include font-udmincho;
  }

  ul {
    margin-left: 0;
  }
}

#about,
#a11y,
#service,
#process,
#works {
  margin-bottom: 8rem;

  @include breakpoint (medium only) {
    margin-bottom: 5rem;
  }

  @include breakpoint (small only) {
    margin-bottom: 3rem;
  }
}

// about

// A11y 
#a11y {

  img {
    width: 100%;
  }
}

// About
#about {
  h2 {
    span:lang(en) {
      @include font-cortado;
      font-size: 6rem;

      @include breakpoint (medium down) {
        font-size: 4rem;
      }
    }
  }

  .layer-overlay {
    position: relative;
    margin-top: 3rem;

    @include breakpoint(medium down) {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    @include clearfix;

    .layer-upper {
      position: absolute;
      top: 3rem;
      width: 50%;

      @include breakpoint(medium down) {
        position: relative;
        top: 0;
        width: 100%;
      }

      .callout {
        border: none;
        background-color: $cream-yellow;
      }
    }

    .layer-lower {
      width: 50%;
      float: right;

      @include breakpoint (medium down) {
        width: 100%;
      }
    }
  }
}

// Service
#service {
  .grid-y {
    .grid-x {

      .cell {
        padding: 1rem;

        h3 {
          margin-top: 3rem;

          @include breakpoint (medium down) {
            margin-top: 0;
          }
        }

        &.sec2 {
          padding-right: 6rem;

          @include breakpoint (medium down) {
            padding-right: 0;
          }

          h3 {
            margin-top: 0;
          }
        }
      }

      .layer-overlay {
        position: relative;

        .layer-upper {
          position: absolute;
          top: -10rem;
          left: -5rem;

          @include breakpoint (medium down) {
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
    }

    & .cell.medium-5 {
      .grid-x {
        .cell.medium-7 {
          padding-right: 7rem;

          @include breakpoint (medium down) {
            padding-right: 0;
          }
        }
      }
    }
  }
}

// Process
#process {
  h3 {
    margin-bottom: 5rem;

    @include breakpoint (small only) {
      margin-bottom: 2rem;
    }
  }

}

// Works

#works {
  h3 {
    margin-bottom: 5rem;

    @include breakpoint (small only) {
      margin-bottom: 2rem;
    }
  }

}

// FAQ
#faq {
  .media-object-section:last-of-type {
    display: flex;
    align-items: flex-end;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}