// Top page
.video-wrapper {
    top: -5.5rem;
    position: relative;
    z-index: -1;
    height: 80vh;

    @include breakpoint (small only) {
        height: 70vh;
    }

    #hero {
        overflow-x: hidden;

        video {
            width: auto;
            min-width: 100%;
            height: 80vh;
            object-fit: cover;

            @include breakpoint (small only) {
                height: 70vh;
            }
        }

        .title-wrapper {
            @include absolute-center;
            color: $white;
            width: 100%;

            @include breakpoint (small only) {
                top: 50%;
            }

            h1 {
                font-family: "cortado", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 12rem;
                text-align: center;

                @include breakpoint (medium only) {
                    font-size: 8rem;
                }

                @include breakpoint (small only) {
                    font-size: 3rem;
                }
            }

            p {
                font-size: 3rem;
                text-align: center;
                font-family: "fot-udmincho-pr6n", sans-serif;
                font-weight: 300;
                font-style: normal;

                @include breakpoint (medium only) {
                    font-size: 2rem;
                }

                @include breakpoint (small only) {
                    font-size: 1rem;
                }

                span {
                    background-color: rgba($color: #000000, $alpha: .75);
                    padding: .5rem 1rem;
                }
            }
        }
    }
}

// Other page
.header-image-wrapper {
    .cell {
        position: relative;

        img {
            position: relative;
            top: -5.5rem;
            width: 100%;
            aspect-ratio: 4 / 1;
            object-fit: cover;
            @include breakpoint (small only){
                aspect-ratio: 16 / 9;
            }
        }

        .title-wrapper {
            position: absolute;
            top: 0;
            color: $white;
            width: 100%;

            h1 {
                font-size: 5rem;
                text-align: center;
                span {
                    background-color: rgba($color: $black, $alpha: .8);
                    padding: .5rem 1rem;
                }

                @include breakpoint (medium only) {
                    font-size: 3rem;
                }

                @include breakpoint (small only) {
                    font-size: 2rem;
                }
            }

            p {
                font-size: 3rem;
                text-align: center;
                font-family: "fot-udmincho-pr6n", sans-serif;
                font-weight: 300;
                font-style: normal;

                @include breakpoint (medium only) {
                    font-size: 2rem;
                }

                @include breakpoint (small only) {
                    font-size: 1rem;
                }

                span {
                    background-color: rgba($color: #000000, $alpha: .75);
                    padding: .5rem 1rem;
                }
            }
        }
    }
}