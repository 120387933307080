@mixin font-cortado {
    font-family: "cortado", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin font-udmincho {
    font-family: "fot-udmincho-pr6n", sans-serif;
    font-weight: 300;
    font-style: normal;
}

// hr
hr {
    border-color: $gray;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

#page {
    h2,h3,h4,h5,h6 {
        font-weight: bold;
    }
    h2 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 4rem;
        margin-top: 3rem;
        &:first-of-type {
            margin-top: 0;
        }
    }
    h3,h4,h5,h6 {
        margin-top: 1.5rem;
    }
    h3 {
        margin-bottom: 2rem;
        border-left: .5rem solid $black;
        padding-left: .5rem;
    }
    h4 {
        margin-bottom: 2rem;
        margin-left: 1em;
    }
    h5 {
        margin-bottom: 1.5rem;
        margin-left: 1.5em;
    }
    h6 {
        margin-bottom: 1.5rem;
        margin-left: 2em;
    }
}

// a11y.html
#page #a11y {
    h3 {
        // font-size: 2.4rem;

        // @include breakpoint (small only) {
        //     font-size: 1.8rem;
        // }

        &.lead {
            font-size: 3rem;
            padding-left: 5rem;
            border-left: none;
            @include breakpoint (small only) {
                font-size: 1.8rem;
                padding-left: 3rem;
            }

            &>span {
                text-indent: -5rem;
                display: inline-block;

                @include breakpoint (small only) {
                    text-indent: -3rem;
                }
            }
        }
    }

    .color-panel {
        padding: 1rem;

        h3 {
            font-weight: bold;
        }

        &01 {
            background-color: $coral;
        }

        &02 {
            background-color: $canary-deep;
        }

        &03 {
            background-color: $mint-green;
        }

        &04 {
            background-color: $lavender;
        }

        &05 {
            background-color: $maccha-green;
        }

        &06 {
            background-color: $neon-blue;
        }

        &07 {
            background-color: $yellow;
        }

    }
}