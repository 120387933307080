html {
    height: 100%;

    body {
        background-color: $cream-yellow;
        height: 100%;
        position: relative;
        .off-canvas-content {
            margin-top: -5.5rem;
        }
    }
}

#page {
section {
    margin-bottom: 10rem;
}
    // a11y.html
    #a11y {
        .grid-x {
            margin-bottom: 10rem;
            @include breakpoint (small only){
                margin-bottom: 3rem;
            }
            .cell {
                &.answer {
                    margin-top: 3rem;
                }

                .callout {
                    @include shadow($prototype-box-shadow);
                    padding: 2rem;
                    font-size: 160%;

                    @include breakpoint(small only) {
                        padding: 1rem;
                        font-size: 120%;
                    }

                    .media-object {
                        .media-object-section {
                            &:first-of-type {
                                display: flex;
                                align-items: center;

                            }

                            &:last-of-type {
                                padding-left: 1.5rem;

                                li {
                                    margin-bottom: 2rem;

                                    @include breakpoint(small only) {
                                        margin-bottom: .5rem;
                                    }

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        #for-a11y {
            .media-object .media-object-section:first-of-type {

                @include breakpoint (small only) {
                    margin-bottom: 2rem;
                }
            }
        }
    }
    // service.html
}