.accordion {
    margin-bottom: 5rem;
    @include shadow($prototype-box-shadow);
    li.accordion-item {
        a.accordion-title {
            background-color: $dark-gray;
            color: $white;
            text-decoration: none!important;
            font-size: 1rem;
            line-height: 1.5;
            text-indent: -2.25rem;
            padding-left: 3rem;
            padding-right: 3.5rem;
            span.question {
                font-style: italic;
                font-size: 1.75rem;
                color: $coral;
                font-weight: bold;
                margin-right: .5rem;
            }
        }
        .accordion-content {
            span.answer {
                font-size: 1.75rem;
                font-style: italic;
                font-weight: bold;
            }
        }        
    }
}