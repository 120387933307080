.skip-link {
    margin-bottom: 0;
    text-align: center;
    a {
        color: $yellow;
        font-weight: bold;
        text-decoration: underline;
    }
}
// a:focus,
// button:focus,
// input:focus {
    //     outline: 2.5px solid $crimson;
    //     // outline-offset: 1rem;    
    // }
    .arrow-list li a:focus {
        outline-offset: 1rem;    
    }
    .card-list .card a:focus {
        outline-offset: .5rem;
    }
    blockquote {
        color: $a11y-gray;
        font-style: italic;
    }
    #page a {
        text-decoration: underline;
        &.button {
            text-decoration: none;
        }
}