    .title-bar {
        background-color: transparent;
        z-index: 10;
        .title-bar-left.logo-wrapper {
            padding: .25rem 1rem;
            a {
                color: $white;
                @include font-cortado;
                background-color: #000;
                border-radius: 50%;
                display: block;
                width: 3.5rem;
                height: 3.5rem;
                padding: .1rem;
                position: relative;
                &:hover,
                &:focus {
                    color: $crimson;
                }
                .logo {
                    font-size: 1.5rem;
                    position: absolute;
                    top: 20%;
                }
                // img {
                //     width: 4rem;
                // }
            }
        }
        .title-bar-right {
            padding: .25rem 1rem;
            .round-hamburger-icon {
                @include hamburger($white, $crimson, 1.5rem, 1.2rem, .2rem, 3);
                border-radius: 50%;
                background-color: #000;
                padding: 1.5rem;
                &::after {
                    top: 33%;
                    width: 70%;
                    @include horizontal-center;
                }
            }
        }
    }
