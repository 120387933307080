.button {
    transition: transform 0.2s ease;
    &:hover,
    &:focus {
        transform: translate(2px, 2px);
    }
}
footer {
    .button {
        background-color: $crimson;
        color: $black;
        font-size: 1.6rem!important;
        font-weight: bold;
        &:hover,
        &:focus {
            background-color: darken($color: $crimson, $amount: 20%);
        }
      }
}