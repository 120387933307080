#about {
    table {
        @include shadow($prototype-box-shadow);

        tbody {
            tr {
                th {
                    text-align: left;
                    width: 20%;
                    @include breakpoint (small only){
                        width: 30%;
                    }
                }
                td {
                    width: 80%;
                    @include breakpoint (small only){
                        width: 70%;
                    }
                }
            }
        }
    }
}