#lightbox {
 .lb-dataContainer {
    padding: 1rem;
    .lb-caption {
        font-size: 1rem;
        line-height: 1.4;
        font-weight: normal;
        a {
            font-weight: bold;
            text-decoration: underline;
        }
    }
 }   
}