#top footer,
#page footer {
    height: 100%;
    position: relative;
    background-image: url(#{$path}assets/img/common/footer-bg.jpg);
    background-size: cover;
    background-color: rgba($color: #000000, $alpha: .8);
    background-blend-mode: multiply;


    hr {
        border-bottom-color: $a11y-gray;
    }

    .grid-container {
        margin-bottom: 1.5rem;
        .footer-nav-wrapper {
            ul.menu.vertical {
                height: 16rem;
                list-style: none;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                @include breakpoint (small only) {
                    flex-wrap: nowrap;
                    height: auto;
                    align-items: center;
                }

                li {
                    margin-bottom: 1rem;
                    a {
                        line-height: 1.5;
                        font-size: 1.2rem;
                        color: $cream-yellow;
                        &:hover,
                        &:focus {
                            color: $crimson;
                        }
                @include breakpoint (small only) {
                    text-align: center;
                }
                    }
                }
            }
        }

        .address-wrapper {
            h2 {
                color: $white;
                @include font-cortado;
            }

            address {
                color: $white;
                text-align: left;
                @include breakpoint (medium down) {
                    text-align: center;
                }
            }
        }
    }

    small {
        color: #fff;
        display: block;
        background-color: $black;
        padding: .5rem;
    }
}