footer {
  form {
    input,
    textarea {
      &::placeholder {
        color: $a11y-gray;
      }
    }

    .form-left {
      label {
        @include element-invisible
      }
      input {
        margin-bottom: 2rem;
      }
    }

    .form-right {
      fieldset {
        legend {
          color: $white;
        }

        input[type=checkbox] {
          scale: (1.5);
        }
        label {
          color: $white;
          font-size: 1rem;
          display: inline-block;
          margin-left: 1rem;
        }
      }

    }

  }
}