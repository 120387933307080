$black : #262626;
$gray : #8f8f8f;
$ink-gray : #474857;
$light-gray : #F8F8FF;
$dark-gray : #2e2f38;
$a11y-gray : #757575;
$crimson : #EC5D56;
$coral : #fc7753;
$deep-coral: #DD3303;
$mint-green : #66d7d1;
$red : #ff0000;
$lavender : #c1839f;
$yellow : #FFEF00;
$canary-deep : #ffc40c;
$cream-yellow : #FCF6DF;
$maccha-green : #bfcc94;
$neon-blue : #3772ff;
.bg-crimson {
    background-color: $crimson;
}
.red {
    color: $red;
}