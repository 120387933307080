@include breakpoint (small only){
    body {
        header {
            // position: relative;
            top: 0;
            .title-bar.sticky {
                top: 0!important;
            }
        }
        #top,
        #page {
            h2 {
                font-size: 1.75rem;
                margin-bottom: 1rem;
            }
            h3 {
                font-size: 1.5rem;
                font-weight: normal;
            }
            h4 {
                font-size: 1.25rem;
                margin-bottom: 1rem;
            }
            h4 {
                font-size: 1.2rem;
            }
            hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        section {
            margin-bottom: 3rem;
        }
        }
    }
}