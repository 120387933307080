// Top Works
.card-list {
  list-style: none;

  li.card {
    margin-bottom: 3rem;
    transition: transform 0.2s linear;
    background-color: transparent;
    border: none;
    flex-grow: inherit;

    &:hover,
    &:focus {
      transform: scale(1.05);
      z-index: 2;
    }

    a {
      color: $white;
      position: relative;
      width: 100%;

      img {
        aspect-ratio: 4 / 3;
        object-fit: cover;
        object-position: top;
        @include drop-shadow;
      }

      .card-section {
        position: absolute;
        bottom: 0;
        padding: .5rem;
        background-color: rgba($color: $black, $alpha: .8);
        width: 100%;

        p {
          font-size: 90%;
        }
      }
    }
  }
}
