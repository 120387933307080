#page,
#top {
    .arrow-list {
        list-style: none;
        display: flex;
        li {
            margin-top: 3rem;
            a {
                display: inline-block;
                >span {
                    background-color: $deep-coral;
                    color: $white;
                    font-weight: bold;
                    font-size: 120%;
                    &:hover {
                        background-color: darken($color: $coral, $amount: 25%);
                    }
                    
                }
            }
        }
        
        &.l2r {
            justify-content: flex-start;
            
            a {
                >span {
                    padding: 1rem 1.5rem 1rem 2.5rem;
                    
                    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
                }
            }
        }
        
        &.r2l {
            justify-content: flex-end;
            
            a {
                >span {
                    
                    padding: 1rem 2.5rem 1rem 1.5rem;
                    clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 10% 100%, 0% 50%, 10% 0%);
                }
            }
        }
    }

    .kome, .note {
        list-style: none;
        padding: 0 1rem;
        li {
            position: relative;
            &::before {
                position: absolute;
                color: $red;
                left: -1rem;
                top: 0;
            }
        }
    }
    .kome {
        li {
            &::before {
                content: '※';
            }
        }
    }
    .note {
        li {
            &::before {
                content: '*';
            }
        }
    }


    // Process Arrow Nav
    ul.box-arrow {
        list-style: none;
        display: flex;
        margin-left: 0;

        @include breakpoint (medium down) {
            flex-direction: column;
        }

        li {
            // clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
            clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
            padding: 1rem 1.5rem 1rem 2.5rem;
            transition: transform 0.2s linear;

            &:hover,
            &:focus {
                transform: scale(1.2)
            }

            @include breakpoint (medium down) {
                width: 100%;
                clip-path: polygon(100% 0%, 100% 80%, 50% 100%, 0% 80%, 0% 0%);
                transition: none;
                margin-bottom: 1rem;

                &:hover,
                &:focus {
                    transform: none;
                }
            }

            &:first-of-type {
                clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);

                @include breakpoint (medium down) {
                    clip-path: polygon(100% 0%, 100% 80%, 50% 100%, 0% 80%, 0% 0%);

                    &:hover,
                    &:focus {
                        transform: none;
                    }
                }
            }


            &.box01 {
                background-color: #E4E9F6;
            }

            &.box02 {
                background-color: #CEDAF0;
            }

            &.box03 {
                background-color: #CBDAE6;
            }

            &.box04 {
                background-color: #9BBBD7;
            }

            &.box05 {
                background-color: #FBEAE2;
            }

            &.box06 {
                background-color: #F9DCCE;
            }

            &.box07 {
                background-color: #F9DADA;
            }

            &.box08 {
                background-color: #E2C2BB;
            }

            &.box09 {
                background-color: #E1BCD0;
            }

            &.box10 {
                background-color: #C3F7E0;
            }

            &.box11 {
                background-color: #C4F7C3;
            }

            &.box12 {
                background-color: #BBEDE5;
            }

            &.box13 {
                background-color: #CEEDBB;
            }


            h4 {
                color: $dark-gray;
                margin-left: .5em;
                text-indent: -1em;
                @include breakpoint (small only){
                    margin-top: 0;
                }
            }

            p {
                color: $dark-gray;
            }
        }
    }

    // bubble list
    .bubble-wrapper {
        list-style: none;

        .bubble-l,
        .bubble-r {
            margin-top: 3rem;
            padding: 1rem;
            position: relative;
            width: 20rem;
            height: 7rem;
            background: #FFFFFF;
            border-radius: 10px;
            border: #7F7F7F solid 3px;
            @include breakpoint (small only){
                width: 90%;
            }
            &:after {
                content: '';
                position: absolute;
                border-style: solid;
                border-color: transparent #FFFFFF;
                display: block;
                width: 0;
                z-index: 1;
            }
            &:before {
                content: '';
                position: absolute;
                border-style: solid;
                border-color: transparent #7F7F7F;
                display: block;
                width: 0;
                z-index: 0;
            }
            p {
                font-weight: bold;
                font-size: 120%;
            }
        }

        .bubble-l {
            @include breakpoint (small only){
                margin-left: 1rem;
            }
            &:after {
                border-width: 15px 15px 15px 0;
                left: -15px;
                top: 45px;
            }

            &:before {
                border-width: 17px 17px 17px 0;
                left: -20px;
                top: 43px;
            }
        }

        .bubble-r {
            margin-left: 4rem;
            @include breakpoint (small only){
                margin-left: .5rem;
            }
            &:after {
                border-width: 15px 0 15px 15px;
                z-index: 1;
                right: -15px;
                top: 45px;
            }

            &:before {
                border-width: 17px 0 17px 17px;
                right: -20px;
                top: 43px;
            }
        }


    }

}