$value:100;

@while $value>0 {
  .ws__#{$value} {
    width: $value + 0%;
    @include breakpoint(small only){
        width: 100%;
    }
  }

  $value:$value - 5;
}
$value:100;

@while $value>0 {
  .w__#{$value} {
    width: $value + 0%;
  }

  $value:$value - 5;
}

